import './principal.css';

function Principal (){
    return(
        <main className='principal-conteudo'>
            <div className='div-texto'>
                <h2 className='principal-texto'> O site da sua empresa <br></br>em boas mãos!</h2>
                <p className='principal-paragrafo'> Desenvolvemos os melhores softwares <br></br> para o seu negócio.</p> 
            </div>
       </main>
    )
}

export default Principal;