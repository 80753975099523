import React from 'react';
import './sobre.css';



function Sobre() {
  return (
    <div className='sobre-container'>
      <section className='sobre' id="sobre">
        <p className='paragrafo-sobre'>
          Na Avant Technology, trabalhamos  com desenvolvimento, manutenção, suporte técnico,
          domínio e hospedagem de sites, landing pages e criação de logos.  <br></br> <br></br>

          Transformamos ideias em realidade digital, nossa abordagem única combinamos expertise, inovação, técnica e design de ponta, 
          para impulsionar a presença online  das empresas para  novos patamares.
        </p>
      </section>
    </div>
  );
}

export default Sobre;


