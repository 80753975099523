import { useState } from 'react';
import './contato.css';
import { FaEnvelope, FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import emailjs from 'emailjs-com';





function Contato() {
    const numeroWhatsApp = "5519999529126";
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [message, setMessage] = useState('')

    function sendEmail(e) {
        e.preventDefault();

        if (name === '' || email === '' || telefone === '' || message === '') {
            alert("POR FAVOR, PREENCHA TODOS OS CAMPOS!");
            return;
        }

        const templateParams = {
            from_name: name,
            message: message,
            email: email,
            telefone: telefone
        }

        emailjs.send( "service_f8ceeor", "template_sxg9n6c", templateParams, "vbLzr9aiXikt2vg4b" )
            .then((response) => {
                setName('')
                setEmail('')
                setTelefone('')
                setMessage('')
                alert("EMAIL ENVIADO COM SUCESSO!", response.status, response.text)
            }, (err) => {
                alert("ERRO AO ENVIAR: ", err)
            })

    }


    return (
        <div className="contato-container" id='contato'>

            <form className='form-container' onSubmit={sendEmail}>

                <input
                    type="text"
                    placeholder="Nome"
                    className="input-nome"
                    name="nome"
                    onChange={(e) => setName(e.target.value)}
                    value={name} />



                <input
                    type="email"
                    placeholder="E-mail"
                    className="input-email"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email} />



                <input
                    type="tel"
                    placeholder="Telefone (99) 9 9999-9999"
                    className="input-telefone"
                    name="telefone"
                    onChange={(e) => setTelefone(e.target.value)}
                    value={telefone} />



                <textarea
                    placeholder="QUAL SEU INTERESSE? (Desenvolvimento de site, manutenção, domínio e hospedagem, landing page ou logotipo)"
                    className="input-interesse"
                    rows="4"
                    name="interesse"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message} />


                <input
                    type="submit"
                    className="enviar-btn"
                    value="enviar" />

            </form>





            <div className='whatsapp-container'>
                <a href={`https://wa.me/${numeroWhatsApp}`} target="_blank" rel="noopener noreferrer">
                    <div className="whatsapp-content">
                        <FaWhatsapp className="whatsapp-icon" />
                    </div>
                </a>
            </div>


            <div className='icones-countainer'>
                <a href="https://www.instagram.com/avant__technology" target="_blank" rel="noopener noreferrer">
                    <div className="instagram-content">
                        <FaInstagram className="instagram-icon" />
                    </div>
                </a>

                <a href="https://www.facebook.com/seu_perfil" target="_blank" rel="noopener noreferrer">
                    <div className="facebook-content">
                        <FaFacebook className="facebook-icon" />
                    </div>
                </a>

                <a href="https://twitter.com/seu_perfil" target="_blank" rel="noopener noreferrer">
                    <div className="twitter-content">
                        <FaTwitter className="twitter-icon" />
                    </div>
                </a>

                <a href="mailto:seu_email@example.com" target="_blank" rel="noopener noreferrer">
                    <div className="email-content">
                        <FaEnvelope className="email-icon" />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Contato;

