import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/header/header';
import Principal from './components/principal/principal';
import Sobre from './components/sobre/sobre';
import Contato from './components/contato/contato';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header /> 
    <Principal />
    <Sobre />
    <Contato />
  </React.StrictMode>
);

reportWebVitals();
