import React, { useState } from 'react';
import logo from '../../imagens/Logo Avant.png';
import './header.css';

function scrollToAbout(event) {
  event.preventDefault();
  const aboutSection = document.getElementById('sobre');
  const headerHeight = document.querySelector('.sec-header').offsetHeight; 
  if (aboutSection) {
    const aboutSectionTop = aboutSection.offsetTop; 
    const scrollToPosition = aboutSectionTop - headerHeight; 
    window.scrollTo({
      top: scrollToPosition,
      behavior: "smooth"
    });
  }
}

function scrollToContato(event) {
  event.preventDefault();
  const contatoSection = document.getElementById('contato');
  if (contatoSection) {
    contatoSection.scrollIntoView({ behavior: "smooth" });
  }
}

function scrollToTop(event) {
  event.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`sec-header ${menuOpen ? 'menu-open' : ''}`}>
      <div className='div-logo'>
        <img className='logo' src={logo} alt="logo da empresa" />
      </div>

      <div className={`div-pages ${menuOpen ? 'menu-open' : ''}`}>
        <a className="link" href='../principal' onClick={scrollToTop}>Início</a>
        <a className="link" href='../sobre/sobre.js' onClick={scrollToAbout}>Sobre</a>
        <a className="link" href='../contato/contato.js' onClick={scrollToContato}>Contato</a>
      </div>

      <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="menu-icon-bar"></div>
        <div className="menu-icon-bar"></div>
        <div className="menu-icon-bar"></div>
      </div>
    </header>
  );
}

export default Header;
